define('@html-next/flexi-layouts/services/device/layout', ['exports', 'ember-copy', '@html-next/flexi-layouts/utils/capitalize', '@html-next/flexi-layouts/lib/monitor', 'ember-window-mock'], function (exports, _emberCopy, _capitalize, _monitor, _emberWindowMock) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Service.extend(Ember.Evented, {
    breakpoints: null,
    height: 500,
    monitor: _monitor.default,
    width: 1000,
    _resizeHandler: null,

    orientation: Ember.computed('width', 'height', function () {
      var resolution = this.getProperties('width', 'height');
      var isLandscape = resolution.width >= resolution.height;

      return isLandscape ? 'landscape' : 'portrait';
    }).readOnly(),

    orientationIsLandscape: Ember.computed.equal('orientation', 'landscape'),
    orientationIsPortrait: Ember.computed.not('orientationIsLandscape'),

    init: function init() {
      this._super();

      this.setupBreakpoints();

      if ((typeof _emberWindowMock.default === 'undefined' ? 'undefined' : _typeof(_emberWindowMock.default)) === 'object' && (typeof document === 'undefined' ? 'undefined' : _typeof(document)) === 'object') {
        this.setupResize();
        this.updateResolution();
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      if ((typeof _emberWindowMock.default === 'undefined' ? 'undefined' : _typeof(_emberWindowMock.default)) === 'object' && (typeof document === 'undefined' ? 'undefined' : _typeof(document)) === 'object') {
        _emberWindowMock.default.removeEventListener('resize', this._resizeHandler, true);
      }
    },
    setupBreakpoints: function setupBreakpoints() {
      var _this = this;

      if (!this.breakpoints) {
        throw new Error('You must configure some breakpoints');
      }

      // sort breakpoints largest to smallest
      this.breakpoints = this.breakpoints.sort(function (a, b) {
        return a.begin > b.begin ? -1 : 1;
      });

      // sort smallest to largest
      var bps = (0, _emberCopy.copy)(this.breakpoints, true).sort(function (a, b) {
        return a.begin > b.begin ? 1 : -1;
      });

      bps.forEach(function (bp, i) {

        Ember.defineProperty(_this, 'is' + (0, _capitalize.default)(bp.name), Ember.computed('width', function () {
          var width = this.get('width');
          var next = bps[i + 1];

          if (next) {
            return width >= bp.begin && width < next.begin;
          }
          return width >= bp.begin;
        }));

        Ember.defineProperty(_this, 'isAtLeast' + (0, _capitalize.default)(bp.name), Ember.computed('width', function () {
          var width = this.get('width');

          return width >= bp.begin;
        }));
      });
    },
    setupResize: function setupResize() {
      var _this2 = this;

      this._resizeHandler = function () {
        Ember.run.debounce(_this2, _this2.updateResolution, 16);
      };
      _emberWindowMock.default.addEventListener('resize', this._resizeHandler, true);
    },


    /**
     * Runs when resized and fires off events
     */
    updateResolution: function updateResolution() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      var oldWidth = this.get('width');
      var oldHeight = this.get('height');
      var width = this._currentWidth();
      var height = this._currentHeight();

      this.setProperties({
        width: width,
        height: height
      });

      if (oldWidth !== width) {
        this.trigger('width-change');
      }

      if (oldHeight !== height) {
        this.trigger('height-change');
      }

      if (oldWidth !== width || oldHeight !== height) {
        this.trigger('resize');
      }
    },
    _currentWidth: function _currentWidth() {
      var widths = [_emberWindowMock.default.document.documentElement.clientWidth, _emberWindowMock.default.innerWidth, _emberWindowMock.default.screen.width // for mobile iOS
      ];
      return Math.min.apply(Math, _toConsumableArray(widths.filter(function (width) {
        return width;
      })));
    },
    _currentHeight: function _currentHeight() {
      var heights = [_emberWindowMock.default.document.documentElement.clienthHeight, _emberWindowMock.default.innerHeight, _emberWindowMock.default.screen.height // for mobile iOS
      ];
      return Math.min.apply(Math, _toConsumableArray(heights.filter(function (height) {
        return height;
      })));
    }
  });
});