define('ember-cli-mirage/route-handler', ['exports', 'ember-cli-mirage/assert', 'ember-cli-mirage/response', 'ember-cli-mirage/route-handlers/function', 'ember-cli-mirage/route-handlers/object', 'ember-cli-mirage/route-handlers/shorthands/get', 'ember-cli-mirage/route-handlers/shorthands/post', 'ember-cli-mirage/route-handlers/shorthands/put', 'ember-cli-mirage/route-handlers/shorthands/delete', 'ember-cli-mirage/route-handlers/shorthands/head'], function (exports, _assert, _response, _function, _object, _get, _post, _put, _delete, _head) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var DEFAULT_CODES = { get: 200, put: 204, post: 201, 'delete': 204 };

  function createHandler(_ref) {
    var verb = _ref.verb,
        schema = _ref.schema,
        serializerOrRegistry = _ref.serializerOrRegistry,
        path = _ref.path,
        rawHandler = _ref.rawHandler,
        options = _ref.options;

    var handler = void 0;
    var args = [schema, serializerOrRegistry, rawHandler, path, options];
    var type = Ember.typeOf(rawHandler);

    if (type === 'function') {
      handler = new (Function.prototype.bind.apply(_function.default, [null].concat(args)))();
    } else if (type === 'object') {
      handler = new (Function.prototype.bind.apply(_object.default, [null].concat(args)))();
    } else if (verb === 'get') {
      handler = new (Function.prototype.bind.apply(_get.default, [null].concat(args)))();
    } else if (verb === 'post') {
      handler = new (Function.prototype.bind.apply(_post.default, [null].concat(args)))();
    } else if (verb === 'put' || verb === 'patch') {
      handler = new (Function.prototype.bind.apply(_put.default, [null].concat(args)))();
    } else if (verb === 'delete') {
      handler = new (Function.prototype.bind.apply(_delete.default, [null].concat(args)))();
    } else if (verb === 'head') {
      handler = new (Function.prototype.bind.apply(_head.default, [null].concat(args)))();
    }
    return handler;
  }

  var RouteHandler = function () {
    function RouteHandler(_ref2) {
      var schema = _ref2.schema,
          verb = _ref2.verb,
          rawHandler = _ref2.rawHandler,
          customizedCode = _ref2.customizedCode,
          options = _ref2.options,
          path = _ref2.path,
          serializerOrRegistry = _ref2.serializerOrRegistry;

      _classCallCheck(this, RouteHandler);

      this.verb = verb;
      this.customizedCode = customizedCode;
      this.serializerOrRegistry = serializerOrRegistry;
      this.handler = createHandler({ verb: verb, schema: schema, path: path, serializerOrRegistry: serializerOrRegistry, rawHandler: rawHandler, options: options });
    }

    _createClass(RouteHandler, [{
      key: 'handle',
      value: function handle(request) {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve) {
          _this._getMirageResponseForRequest(request).then(function (mirageResponse) {
            _this.serialize(mirageResponse, request).then(function (serializedMirageResponse) {
              resolve(serializedMirageResponse.toRackResponse());
            });
          });
        });
      }
    }, {
      key: '_getMirageResponseForRequest',
      value: function _getMirageResponseForRequest(request) {
        var result = void 0;
        try {
          /*
           We need to do this for the #serialize convenience method. Probably is
           a better way.
          */
          if (this.handler instanceof _function.default) {
            this.handler.setRequest(request);
          }

          result = this.handler.handle(request);
        } catch (e) {
          if (e instanceof _assert.MirageError) {
            throw e;
          } else {
            var message = Ember.typeOf(e) === 'string' ? e : e.message;
            var error = new _assert.MirageError('Your ' + request.method + ' handler for the url ' + request.url + ' threw an error: ' + message);

            result = new _response.default(500, {}, error.message);
          }
        }

        return this._toMirageResponse(result);
      }
    }, {
      key: '_toMirageResponse',
      value: function _toMirageResponse(result) {
        var _this2 = this;

        var mirageResponse = void 0;

        return new Ember.RSVP.Promise(function (resolve) {
          Ember.RSVP.Promise.resolve(result).then(function (response) {
            if (response instanceof _response.default) {
              mirageResponse = result;
            } else {
              var code = _this2._getCodeForResponse(response);
              mirageResponse = new _response.default(code, {}, response);
            }
            resolve(mirageResponse);
          });
        });
      }
    }, {
      key: '_getCodeForResponse',
      value: function _getCodeForResponse(response) {
        var code = void 0;
        if (this.customizedCode) {
          code = this.customizedCode;
        } else {
          code = DEFAULT_CODES[this.verb];
          // Returning any data for a 204 is invalid
          if (code === 204 && response !== undefined && response !== '') {
            code = 200;
          }
        }
        return code;
      }
    }, {
      key: 'serialize',
      value: function serialize(mirageResponsePromise, request) {
        var _this3 = this;

        return new Ember.RSVP.Promise(function (resolve) {
          Ember.RSVP.Promise.resolve(mirageResponsePromise).then(function (mirageResponse) {
            mirageResponse.data = _this3.serializerOrRegistry.serialize(mirageResponse.data, request);
            resolve(mirageResponse);
          });
        });
      }
    }]);

    return RouteHandler;
  }();

  exports.default = RouteHandler;
});