define('ember-composable-form/pods/components/composable-form/option-group/option/component', ['exports', 'ember-composable-form/pods/components/composable-form/option-group/option/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    tagName: '',

    selected: Ember.computed('value', 'groupValue', function () {
      if (typeof this.groupValue === 'boolean') {
        return this.groupValue == this.value;
      }
      return this.groupValue && this.groupValue.includes(this.value);
    }),

    handleUpdate: function handleUpdate(selected) {
      if (this.onUpdate) {
        this.onUpdate(this.value, selected);
      }
    }
  });
});