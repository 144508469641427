define('admin/routes', ['exports', 'ember-engines/routes'], function (exports, _routes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = (0, _routes.default)(function () {
    this.route('companies', function () {
      this.route('company', { path: '/:company_id' });
    });
    this.route('users', function () {
      this.route('user', { path: '/:user_id' });
    });
  });
});