define('ember-cli-mirage/response', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var Response = function () {
    function Response(code) {
      var headers = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var data = arguments[2];

      _classCallCheck(this, Response);

      this.code = code;
      this.headers = headers;

      // Default data for "undefined 204" responses to empty string (no content)
      if (code === 204) {
        if (data !== undefined && data !== '') {
          (true && Ember.warn('Mirage: One of your route handlers is returning a custom\n          204 Response that has data, but this is a violation of the HTTP spec\n          and could lead to unexpected behavior. 204 responses should have no\n          content (an empty string) as their body. [warning id:\n          ember-cli-mirage.warn-response-204-non-empty-payload]', false, { id: 'ember-cli-mirage.warn-response-204-non-empty-payload' }));
        } else {
          this.data = '';
        }

        // Default data for "empty untyped" responses to empty JSON object
      } else if ((data === undefined || data === '') && !this.headers.hasOwnProperty('Content-Type')) {
        this.data = {};
      } else {
        this.data = data;
      }

      // Default "untyped" responses to application/json
      if (!this.headers.hasOwnProperty('Content-Type')) {
        this.headers['Content-Type'] = 'application/json';
      }
    }

    _createClass(Response, [{
      key: 'toRackResponse',
      value: function toRackResponse() {
        return [this.code, this.headers, this.data];
      }
    }]);

    return Response;
  }();

  exports.default = Response;
});