define('ember-cli-mirage/route-handlers/function', ['exports', 'ember-cli-mirage/route-handlers/base', 'ember-cli-mirage/assert', 'ember-cli-mirage/utils/inflector'], function (exports, _base, _assert, _inflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var FunctionRouteHandler = function (_BaseRouteHandler) {
    _inherits(FunctionRouteHandler, _BaseRouteHandler);

    function FunctionRouteHandler(schema, serializerOrRegistry, userFunction, path) {
      _classCallCheck(this, FunctionRouteHandler);

      var _this = _possibleConstructorReturn(this, (FunctionRouteHandler.__proto__ || Object.getPrototypeOf(FunctionRouteHandler)).call(this));

      _this.schema = schema;
      _this.serializerOrRegistry = serializerOrRegistry;
      _this.userFunction = userFunction;
      _this.path = path;
      return _this;
    }

    _createClass(FunctionRouteHandler, [{
      key: 'handle',
      value: function handle(request) {
        return this.userFunction(this.schema, request);
      }
    }, {
      key: 'setRequest',
      value: function setRequest(request) {
        this.request = request;
      }
    }, {
      key: 'serialize',
      value: function serialize(response, serializerType) {
        var serializer = void 0;

        if (serializerType) {
          serializer = this.serializerOrRegistry.serializerFor(serializerType, { explicit: true });
        } else {
          serializer = this.serializerOrRegistry;
        }

        return serializer.serialize(response, this.request);
      }
    }, {
      key: 'normalizedRequestAttrs',
      value: function normalizedRequestAttrs() {
        var modelName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
        var path = this.path,
            request = this.request,
            requestHeaders = this.request.requestHeaders;

        var attrs = void 0;

        var lowerCaseHeaders = {};
        for (var header in requestHeaders) {
          lowerCaseHeaders[header.toLowerCase()] = requestHeaders[header];
        }
        if (/x-www-form-urlencoded/.test(lowerCaseHeaders['content-type'])) {
          attrs = this._getAttrsForFormRequest(request);
        } else {
          if (modelName) {
            if ((0, _inflector.dasherize)(modelName) !== modelName) {
              // eslint-disable-next-line no-console
              console.warn('Mirage [deprecation]: You called normalizedRequestAttrs(\'' + modelName + '\'), but normalizedRequestAttrs was intended to be used with the dasherized version of the model type. Please change this to normalizedRequestAttrs(\'' + (0, _inflector.dasherize)(modelName) + '\'). This behavior will be removed in 1.0.');
              modelName = (0, _inflector.camelize)(modelName);
            }
          } else {
            modelName = this.getModelClassFromPath(path);
          }

          (0, _assert.default)(this.schema.hasModelForModelName(modelName), 'You\'re using a shorthand or the #normalizedRequestAttrs helper but the detected model of \'' + modelName + '\' does not exist. You might need to pass in the correct modelName as the first argument to #normalizedRequestAttrs.');

          attrs = this._getAttrsForRequest(request, modelName);
        }

        return attrs;
      }
    }]);

    return FunctionRouteHandler;
  }(_base.default);

  exports.default = FunctionRouteHandler;
});