define('@html-next/flexi-layouts/mixins/container', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    deviceLayout: Ember.inject.service('device/layout'),
    width: 0,
    inserted: false,
    classNameBindings: ['breakpointClass'],
    breakpointClass: Ember.computed('inserted', 'width', function () {
      var bps = this.get('deviceLayout.breakpoints');

      if (!this.get('inserted')) {
        return 'container-' + bps[0].prefix;
      }

      var width = this.element.clientWidth;

      for (var i = 0; i < bps.length; i++) {
        if (width >= bps[i].begin) {
          return 'container-' + bps[i].prefix;
        }
      }
      return 'container-breakpoint-unavailable';
    }),

    _elementResize: null,
    elementResize: function elementResize(dims) {
      this.set('width', dims.width);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super();
      this._elementResize = this.elementResize.bind(this);

      Ember.run.schedule('afterRender', function () {
        // Ember before v2.10 can arrive in afterRender with a null element.
        // Details here: https://github.com/html-next/flexi/issues/101
        if (_this.isDestroying) {
          return;
        }

        _this.set('inserted', true);
        _this.get('deviceLayout').monitor.addElementHandler(_this.element, _this._elementResize);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super();
      this.set('inserted', false);
      this.get('deviceLayout').monitor.removeElementHandler(this.element, this._elementResize);
      this._elementResize = null;
    }
  });
});