define('@html-next/flexi-layouts/components/flexi-grid', ['exports', '@html-next/flexi-layouts/mixins/container', '@html-next/flexi-layouts/templates/components/flexi-grid'], function (exports, _container, _flexiGrid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_container.default, {
    layout: _flexiGrid.default,
    tagName: 'grid',
    attributeBindings: ['responsive'],
    responsive: true
  });
});