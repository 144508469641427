define('cc-components/pods/components/cc-composable-form/form-actions/form-action/component', ['exports', 'ember-composable-form/pods/components/composable-form/form-actions/form-action/component', 'cc-components/pods/components/cc-composable-form/form-actions/form-action/template'], function (exports, _component, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    layout: _template.default,
    color: 'primary',
    btnName: 'form-action',
    icon: 'long-arrow-left',
    noMargin: false,
    noPadding: false,
    displayIcon: false
  });
});