define('ember-composable-form/pods/components/composable-form/input/field/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    attributeBindings: ['disabled', 'focusOut', 'input', 'placeholder', 'readonly', 'required', 'type', 'value', 'name'],

    classNames: ['composable-form-input--field'],

    classNameBindings: ['errorClass'],

    disabled: false,

    placeholder: '',

    readonly: false,

    tagName: 'input',

    errorClass: Ember.computed('isValid', function () {
      return !Ember.get(this, 'isValid') ? 'has-error' : 'valid';
    })
  });
});