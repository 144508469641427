define('cc-components/pods/components/cc-progress-dot/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: [':cc-progress-dot', ':br4', ':h0-75', ':w0-75', ':pa0', 'color'],

    color: Ember.computed('selected', function () {
      return this.selected ? 'primary' : '';
    }),

    tagName: 'button'
  });
});