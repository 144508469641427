define('cc-components/pods/components/cc-card/component', ['exports', 'cc-components/pods/components/cc-card/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'article',
    classNameBindings: [':mw5', ':center', ':bg-white', ':br3', ':pa3', ':pa4-ns', ':mv3', ':ba', ':b--black-10']
  });
});