define('ember-cli-mirage/serializers/json-api-serializer', ['exports', 'ember-cli-mirage/serializer', 'ember-cli-mirage/utils/inflector', 'lodash/get', 'lodash', 'ember-cli-mirage/assert'], function (exports, _serializer, _inflector, _get2, _lodash, _assert) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var JSONAPISerializer = _serializer.default.extend({
    keyForModel: function keyForModel(modelName) {
      return (0, _inflector.dasherize)(modelName);
    },
    keyForCollection: function keyForCollection(modelName) {
      return (0, _inflector.dasherize)(modelName);
    },
    keyForAttribute: function keyForAttribute(attr) {
      return (0, _inflector.dasherize)(attr);
    },
    keyForRelationship: function keyForRelationship(key) {
      return (0, _inflector.dasherize)(key);
    },
    getHashForPrimaryResource: function getHashForPrimaryResource(resource) {
      this._createRequestedIncludesGraph(resource);

      var resourceHash = this.getHashForResource(resource);
      var hashWithRoot = { data: resourceHash };
      var addToIncludes = this.getAddToIncludesForResource(resource);

      return [hashWithRoot, addToIncludes];
    },
    getHashForIncludedResource: function getHashForIncludedResource(resource) {
      var serializer = this.serializerFor(resource.modelName);
      var hash = serializer.getHashForResource(resource);
      var hashWithRoot = { included: this.isModel(resource) ? [hash] : hash };
      var addToIncludes = [];

      if (!this.hasQueryParamIncludes()) {
        addToIncludes = this.getAddToIncludesForResource(resource);
      }

      return [hashWithRoot, addToIncludes];
    },
    getHashForResource: function getHashForResource(resource) {
      var _this = this;

      var hash = void 0;

      if (this.isModel(resource)) {
        hash = this._getResourceObjectForModel(resource);
      } else {
        hash = resource.models.map(function (m) {
          return _this._getResourceObjectForModel(m);
        });
      }

      return hash;
    },
    getAddToIncludesForResource: function getAddToIncludesForResource(resource) {
      var relationshipPaths = void 0;

      if (this.hasQueryParamIncludes()) {
        relationshipPaths = this.request.queryParams.include.split(',');
      } else {
        var serializer = this.serializerFor(resource.modelName);
        relationshipPaths = serializer.getKeysForIncluded();
      }

      return this.getAddToIncludesForResourceAndPaths(resource, relationshipPaths);
    },
    getAddToIncludesForResourceAndPaths: function getAddToIncludesForResourceAndPaths(resource, relationshipPaths) {
      var _this2 = this;

      var includes = [];

      relationshipPaths.forEach(function (path) {
        var relationshipNames = path.split('.');
        var newIncludes = _this2.getIncludesForResourceAndPath.apply(_this2, [resource].concat(_toConsumableArray(relationshipNames)));
        includes.push(newIncludes);
      });

      return (0, _lodash.default)(includes).flatten().compact().uniqBy(function (m) {
        return m.toString();
      }).value();
    },
    getIncludesForResourceAndPath: function getIncludesForResourceAndPath(resource) {
      var _this3 = this;

      for (var _len = arguments.length, names = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        names[_key - 1] = arguments[_key];
      }

      var nameForCurrentResource = (0, _inflector.camelize)(names.shift());
      var includes = [];
      var modelsToAdd = [];

      if (this.isModel(resource)) {
        var relationship = resource[nameForCurrentResource];

        if (this.isModel(relationship)) {
          modelsToAdd = [relationship];
        } else if (this.isCollection(relationship)) {
          modelsToAdd = relationship.models;
        }
      } else {
        resource.models.forEach(function (model) {
          var relationship = model[nameForCurrentResource];

          if (_this3.isModel(relationship)) {
            modelsToAdd.push(relationship);
          } else if (_this3.isCollection(relationship)) {
            modelsToAdd = modelsToAdd.concat(relationship.models);
          }
        });
      }

      includes = includes.concat(modelsToAdd);

      if (names.length) {
        modelsToAdd.forEach(function (model) {
          includes = includes.concat(_this3.getIncludesForResourceAndPath.apply(_this3, [model].concat(_toConsumableArray(names))));
        });
      }

      return includes;
    },
    _getResourceObjectForModel: function _getResourceObjectForModel(model) {
      var attrs = this._attrsForModel(model, true);
      delete attrs.id;

      var hash = {
        type: this.typeKeyForModel(model),
        id: model.id,
        attributes: attrs
      };

      return this._maybeAddRelationshipsToResourceObjectForModel(hash, model);
    },
    _maybeAddRelationshipsToResourceObjectForModel: function _maybeAddRelationshipsToResourceObjectForModel(hash, model) {
      var _this4 = this;

      var relationships = model.associationKeys.reduce(function (relationships, key) {
        var relationship = model[key];
        var relationshipKey = _this4.keyForRelationship(key);
        var relationshipHash = {};

        if (_this4.hasLinksForRelationship(model, key)) {
          var serializer = _this4.serializerFor(model.modelName);
          var links = serializer.links(model);
          relationshipHash.links = links[key];
        }

        if (_this4.alwaysIncludeLinkageData || _this4._relationshipIsIncludedForModel(key, model)) {
          var data = null;
          if (_this4.isModel(relationship)) {
            data = {
              type: _this4.typeKeyForModel(relationship),
              id: relationship.id
            };
          } else if (_this4.isCollection(relationship)) {
            data = relationship.models.map(function (model) {
              return {
                type: _this4.typeKeyForModel(model),
                id: model.id
              };
            });
          }
          relationshipHash.data = data;
        }

        if (!_lodash.default.isEmpty(relationshipHash)) {
          relationships[relationshipKey] = relationshipHash;
        }

        return relationships;
      }, {});

      if (!_lodash.default.isEmpty(relationships)) {
        hash.relationships = relationships;
      }

      return hash;
    },
    hasLinksForRelationship: function hasLinksForRelationship(model, relationshipKey) {
      var serializer = this.serializerFor(model.modelName);
      var links = void 0;
      if (serializer.links) {
        links = serializer.links(model);

        return links[relationshipKey] != null;
      }
    },
    _relationshipIsIncludedForModel: function _relationshipIsIncludedForModel(relationshipKey, model) {
      if (this.hasQueryParamIncludes()) {
        var graph = this.request._includesGraph;
        var graphKey = this._graphKeyForModel(model);

        // Find the resource in the graph
        var graphResource = void 0;

        // Check primary data
        if (graph.data[graphKey]) {
          graphResource = graph.data[graphKey];

          // Check includes
        } else if (graph.included[(0, _inflector.pluralize)(model.modelName)]) {
          graphResource = graph.included[(0, _inflector.pluralize)(model.modelName)][graphKey];
        }

        // If the model's in the graph, check if relationshipKey should be included
        return graphResource && graphResource.relationships && graphResource.relationships.hasOwnProperty((0, _inflector.dasherize)(relationshipKey));
      } else {
        var relationshipPaths = this.getKeysForIncluded();

        return relationshipPaths.includes(relationshipKey);
      }
    },
    _createRequestedIncludesGraph: function _createRequestedIncludesGraph(primaryResource) {
      var _this5 = this;

      var secondaryResource = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      var graph = {
        data: {}
      };

      if (this.isModel(primaryResource)) {
        var primaryResourceKey = this._graphKeyForModel(primaryResource);
        graph.data[primaryResourceKey] = {};

        this._addPrimaryModelToRequestedIncludesGraph(graph, primaryResource);
      } else if (this.isCollection(primaryResource)) {
        primaryResource.models.forEach(function (model) {
          var primaryResourceKey = _this5._graphKeyForModel(model);
          graph.data[primaryResourceKey] = {};

          _this5._addPrimaryModelToRequestedIncludesGraph(graph, model);
        });
      }

      // Hack :/ Need to think of a better palce to put this if
      // refactoring json:api serializer.
      this.request._includesGraph = graph;
    },
    _addPrimaryModelToRequestedIncludesGraph: function _addPrimaryModelToRequestedIncludesGraph(graph, model) {
      var _this6 = this;

      if (this.hasQueryParamIncludes()) {
        var graphKey = this._graphKeyForModel(model);
        var queryParamIncludes = this.getQueryParamIncludes();

        queryParamIncludes.split(',').forEach(function (includesPath) {
          // includesPath is post.comments, for example
          graph.data[graphKey].relationships = graph.data[graphKey].relationships || {};

          var relationshipKeys = includesPath.split('.').map(_inflector.dasherize);
          var relationshipKey = relationshipKeys[0];
          var graphRelationshipKey = relationshipKey;
          var normalizedRelationshipKey = (0, _inflector.camelize)(relationshipKey);
          var hasAssociation = model.associationKeys.includes(normalizedRelationshipKey);

          (0, _assert.default)(hasAssociation, 'You tried to include "' + relationshipKey + '" with ' + model + ' but no association named "' + normalizedRelationshipKey + '" is defined on the model.');

          var relationship = model[normalizedRelationshipKey];
          var relationshipData = void 0;

          if (_this6.isModel(relationship)) {
            relationshipData = _this6._graphKeyForModel(relationship);
          } else if (_this6.isCollection(relationship)) {
            relationshipData = relationship.models.map(_this6._graphKeyForModel);
          } else {
            relationshipData = null;
          }

          graph.data[graphKey].relationships[graphRelationshipKey] = relationshipData;

          if (relationship) {
            _this6._addResourceToRequestedIncludesGraph(graph, relationship, relationshipKeys.slice(1));
          }
        });
      }
    },
    _addResourceToRequestedIncludesGraph: function _addResourceToRequestedIncludesGraph(graph, resource, relationshipNames) {
      var _this7 = this;

      graph.included = graph.included || {};

      var models = this.isCollection(resource) ? resource.models : [resource];

      models.forEach(function (model) {
        var collectionName = (0, _inflector.pluralize)(model.modelName);
        graph.included[collectionName] = graph.included[collectionName] || {};

        _this7._addModelToRequestedIncludesGraph(graph, model, relationshipNames);
      });
    },
    _addModelToRequestedIncludesGraph: function _addModelToRequestedIncludesGraph(graph, model, relationshipNames) {
      var collectionName = (0, _inflector.pluralize)(model.modelName);
      var resourceKey = this._graphKeyForModel(model);
      graph.included[collectionName][resourceKey] = graph.included[collectionName][resourceKey] || {};

      if (relationshipNames.length) {
        this._addResourceRelationshipsToRequestedIncludesGraph(graph, collectionName, resourceKey, model, relationshipNames);
      }
    },
    _addResourceRelationshipsToRequestedIncludesGraph: function _addResourceRelationshipsToRequestedIncludesGraph(graph, collectionName, resourceKey, model, relationshipNames) {
      graph.included[collectionName][resourceKey].relationships = graph.included[collectionName][resourceKey].relationships || {};

      var relationshipName = relationshipNames[0];
      var relationship = model[(0, _inflector.camelize)(relationshipName)];
      var relationshipData = void 0;

      if (this.isModel(relationship)) {
        relationshipData = this._graphKeyForModel(relationship);
      } else if (this.isCollection(relationship)) {
        relationshipData = relationship.models.map(this._graphKeyForModel);
      }
      graph.included[collectionName][resourceKey].relationships[relationshipName] = relationshipData;

      if (relationship) {
        this._addResourceToRequestedIncludesGraph(graph, relationship, relationshipNames.slice(1));
      }
    },
    _graphKeyForModel: function _graphKeyForModel(model) {
      return model.modelName + ':' + model.id;
    },
    getQueryParamIncludes: function getQueryParamIncludes() {
      return (0, _get2.default)(this, 'request.queryParams.include');
    },
    hasQueryParamIncludes: function hasQueryParamIncludes() {
      return !!this.getQueryParamIncludes();
    },
    typeKeyForModel: function typeKeyForModel(model) {
      return (0, _inflector.dasherize)((0, _inflector.pluralize)(model.modelName));
    },
    getCoalescedIds: function getCoalescedIds(request) {
      var ids = request.queryParams && request.queryParams['filter[id]'];
      if (typeof ids === 'string') {
        return ids.split(',');
      }
      return ids;
    }
  });

  JSONAPISerializer.prototype.alwaysIncludeLinkageData = false;

  exports.default = JSONAPISerializer;
});