define('cc-components/pods/components/cc-button/component', ['exports', 'cc-components/pods/components/cc-button/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    tagName: 'button',

    classNameBindings: [':cc-button', '_fontSize', 'block', 'border', 'color', 'disabled:disabled', 'icon', 'icon:h1-5', 'icon:w1-5', 'noMargin', 'noPadding', 'rounded:br4', 'selected'],

    attributeBindings: ['disabled', 'name'],

    border: false,

    block: true,

    color: 'primary',

    disabled: false,

    fontSize: 5,

    noMargin: false,

    rounded: true,

    showSpinner: false,

    _fontSize: Ember.computed(function () {
      return 'f' + this.fontSize;
    }),

    label: Ember.computed('icon', function () {
      if (!this.icon) {
        return 'Submit';
      }
    }),

    noPadding: Ember.computed(function () {
      if (!this.label) {
        return true;
      }
    }),

    click: function click() {
      var clickAction = Ember.get(this, 'onClick');
      if (clickAction) {
        clickAction();
      }
    }
  });
});