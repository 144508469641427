define('ember-composable-form/pods/components/composable-form/input/component', ['exports', 'ember-composable-form/pods/components/composable-form/base-element/component', 'ember-composable-form/pods/components/composable-form/input/template'], function (exports, _component, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    layout: _template.default,

    classNames: ['composable-form-input'],

    fieldComponent: 'composable-form/input/field',

    type: 'text'
  });
});