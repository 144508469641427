define('ember-composable-form/pods/components/composable-form/button/component', ['exports', 'ember-composable-form/pods/components/composable-form/button/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    attributeBindings: ['aria-pressed'],

    classNames: 'composable-form-button',

    tagName: 'button',

    click: function click() {
      if (this.onClick) {
        this.onClick();
      }
    }
  });
});