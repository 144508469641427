define('cc-components/pods/components/modal/component', ['exports', 'ember-modal-dialog/components/modal-dialog'], function (exports, _modalDialog) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalDialog.default.extend({
    animatable: true,

    wrapperClass: 'modal',

    overlayClass: Ember.computed('noOverlay', function () {
      return this.noOverlay ? 'bg-transparent' : 'bg-black-75';
    })
  });
});