define('cc-components/pods/components/cc-popover/component', ['exports', 'cc-components/pods/components/cc-popover/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    tagName: 'span',

    isOpen: false,
    contentComponent: 'cc-popover/cc-popover-content',
    triggerComponent: 'cc-button',
    popoverAttachment: 'top center',
    popoverClassName: 'popover bg-white',
    popoverTargetAttachment: 'bottom center',

    triggerId: Ember.computed('id', function () {
      return '#' + (this.id || this.elementId);
    })
  });
});