define('@html-next/flexi-layouts/components/flexi-layout', ['exports', '@html-next/flexi-layouts/templates/components/flexi-layout'], function (exports, _flexiLayout) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _flexiLayout.default,
    tagName: '',
    deviceLayout: Ember.inject.service('device/layout')
  });
});