define('cc-components/pods/components/table/loader/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: Ember.HTMLBars.template({
      "id": "gT9v16dZ",
      "block": "{\"symbols\":[],\"statements\":[[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[28,[\"cc-table-loader \",[27,\"if\",[[23,[\"color\"]],[23,[\"color\"]]],null]]]],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"cc-table-loader-rect1\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"cc-table-loader-rect2\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"cc-table-loader-rect3\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"cc-table-loader-rect4\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"cc-table-loader-rect5\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"]],\"hasEval\":false}",
      "meta": {}
    })
  });
});