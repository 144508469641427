define("ember-steps/-private/state-machine/-base", ["exports", "@ember-decorators/object/computed"], function (exports, _computed) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
        var c = arguments.length,
            r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
            d;
        if ((typeof Reflect === "undefined" ? "undefined" : _typeof(Reflect)) === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
            if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
        }return c > 3 && r && Object.defineProperty(target, key, r), r;
    };

    var StateMachine = function (_EmberObject) {
        _inherits(StateMachine, _EmberObject);

        function StateMachine(initialStep) {
            _classCallCheck(this, StateMachine);

            var _this = _possibleConstructorReturn(this, (StateMachine.__proto__ || Object.getPrototypeOf(StateMachine)).call(this));

            _this.stepTransitions = Ember.A();
            if (initialStep) {
                Ember.set(_this, 'currentStep', initialStep);
            }
            return _this;
        }

        _createClass(StateMachine, [{
            key: "addStep",
            value: function addStep(name) {
                this.stepTransitions.pushObject(name);
                if (!this.currentStep) {
                    Ember.set(this, 'currentStep', name);
                }
            }
        }, {
            key: "removeStep",
            value: function removeStep(name) {
                var index = this.stepTransitions.indexOf(name);
                this.stepTransitions.removeAt(index);
            }
        }, {
            key: "activate",
            value: function activate(name) {
                (true && !(Ember.isPresent(name)) && Ember.assert('No step name was provided', Ember.isPresent(name)));
                (true && !(this.stepTransitions.includes(name)) && Ember.assert("\"" + name + "\" does not match an existing step", this.stepTransitions.includes(name)));

                Ember.set(this, 'currentStep', name);
            }
        }]);

        return StateMachine;
    }(Ember.Object);

    exports.default = StateMachine;

    __decorate([(0, _computed.readOnly)('stepTransitions.length')], StateMachine.prototype, "length", void 0);
    __decorate([(0, _computed.readOnly)('stepTransitions.firstObject')], StateMachine.prototype, "firstStep", void 0);
});