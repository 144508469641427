define('cc-components/pods/components/cc-menu/component', ['exports', 'cc-components/pods/components/cc-menu/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    classNames: 'cc-menu list ma0 pa0',

    tagName: 'ul',

    itemClassNames: '',

    _itemClassNames: Ember.computed('itemClassNames', function () {
      return ['flex flex-row items-center no-underline mid-gray hover-blue', this.itemClassNames].join(' ');
    })
  });
});