define('cc-components/pods/components/cc-header/component', ['exports', 'cc-components/pods/components/cc-header/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    classNameBindings: [':lh-copy', 'fontSize', 'fontWeight', 'display'],

    tagName: 'h1',

    size: '1',

    text: null,

    weight: 'bold',

    display: 'db',

    fontSize: Ember.computed('size', function () {
      return 'f' + this.size;
    }),

    fontWeight: Ember.computed('weight', function () {
      var map = {
        1: 'fw1',
        2: 'fw2',
        3: 'fw3',
        4: 'fw4',
        5: 'fw5',
        6: 'fw6',
        7: 'fw7',
        8: 'fw8',
        9: 'fw9',
        bold: 'b',
        light: 'fw3',
        normal: 'normal'
      };
      return map[this.weight];
    })
  });
});