define('ember-composable-form/pods/components/composable-form/component', ['exports', 'ember-composable-form/pods/components/composable-form/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['composable-form'],

    buttonComponent: 'composable-form/button',
    checkboxComponent: 'composable-form/checkbox',
    datepickerComponent: 'composable-form/datepicker',
    fieldsetComponent: 'composable-form/fieldset',
    fileComponent: 'composable-form/file',
    inputComponent: 'composable-form/input',
    labelComponent: 'composable-form/label',
    multiSelectComponent: 'composable-form/multi-select',
    optionGroupComponent: 'composable-form/option-group',
    progressBarComponent: 'composable-form/progress-bar',
    radioButtonComponent: 'composable-form/radio-button',
    sectionComponent: 'composable-form/section',
    selectComponent: 'composable-form/select',
    textareaComponent: 'composable-form/textarea',
    typeaheadComponent: 'composable-form/typeahead',

    validateBeforeSubmit: true
  });
});