define('ember-cli-mirage/server', ['exports', 'ember-cli-mirage/utils/inflector', 'ember-cli-mirage/utils/normalize-name', 'ember-cli-mirage/ember-data', 'ember-cli-mirage/utils/ember-data', 'ember-cli-mirage/utils/is-association', 'pretender', 'ember-cli-mirage/db', 'ember-cli-mirage/orm/schema', 'ember-cli-mirage/assert', 'ember-cli-mirage/serializer-registry', 'ember-cli-mirage/route-handler', 'ember-cli-mirage/orm/associations/belongs-to', 'lodash/pick', 'lodash/assign', 'lodash/find', 'lodash/isPlainObject', 'lodash/isInteger'], function (exports, _inflector, _normalizeName, _emberData, _emberData2, _isAssociation, _pretender, _db, _schema, _assert, _serializerRegistry, _routeHandler, _belongsTo, _pick2, _assign2, _find2, _isPlainObject2, _isInteger2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.defaultPassthroughs = undefined;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  /**
   * Creates a new Pretender instance.
   *
   * @method createPretender
   * @param {Server} server
   * @return {Object} A new Pretender instance.
   * @public
   */
  function createPretender(server) {
    return new _pretender.default(function () {
      this.passthroughRequest = function (verb, path, request) {
        if (server.shouldLog()) {
          console.log('Passthrough request: ' + verb.toUpperCase() + ' ' + request.url);
        }
      };

      this.handledRequest = function (verb, path, request) {
        if (server.shouldLog()) {
          console.groupCollapsed('Mirage: [' + request.status + '] ' + verb.toUpperCase() + ' ' + request.url);
          var requestBody = request.requestBody,
              responseText = request.responseText;

          var loggedRequest = void 0,
              loggedResponse = void 0;

          try {
            loggedRequest = JSON.parse(requestBody);
          } catch (e) {
            loggedRequest = requestBody;
          }

          try {
            loggedResponse = JSON.parse(responseText);
          } catch (e) {
            loggedResponse = responseText;
          }

          console.log({
            request: loggedRequest,
            response: loggedResponse,
            raw: request
          });
          console.groupEnd();
        }
      };

      this.unhandledRequest = function (verb, path) {
        path = decodeURI(path);
        (0, _assert.default)('Your Ember app tried to ' + verb + ' \'' + path + '\',\n         but there was no route defined to handle this request.\n         Define a route that matches this path in your\n         mirage/config.js file. Did you forget to add your namespace?');
      };
    }, { trackRequests: server.shouldTrackRequests() });
  }

  var defaultRouteOptions = {
    coalesce: false,
    timing: undefined
  };

  var defaultPassthroughs = ['http://localhost:0/chromecheckurl', // mobile chrome
  'http://localhost:30820/socket.io' // electron
  ];
  exports.defaultPassthroughs = defaultPassthroughs;


  /**
   * Determine if the object contains a valid option.
   *
   * @method isOption
   * @param {Object} option An object with one option value pair.
   * @return {Boolean} True if option is a valid option, false otherwise.
   * @private
   */
  function isOption(option) {
    if (!option || (typeof option === 'undefined' ? 'undefined' : _typeof(option)) !== 'object') {
      return false;
    }

    var allOptions = Object.keys(defaultRouteOptions);
    var optionKeys = Object.keys(option);
    for (var i = 0; i < optionKeys.length; i++) {
      var key = optionKeys[i];
      if (allOptions.indexOf(key) > -1) {
        return true;
      }
    }
    return false;
  }

  /**
   * Extract arguments for a route.
   *
   * @method extractRouteArguments
   * @param {Array} args Of the form [options], [object, code], [function, code]
   * [shorthand, options], [shorthand, code, options]
   * @return {Array} [handler (i.e. the function, object or shorthand), code,
   * options].
   * @private
   */
  function extractRouteArguments(args) {
    var _args$splice = args.splice(-1),
        _args$splice2 = _slicedToArray(_args$splice, 1),
        lastArg = _args$splice2[0];

    if (isOption(lastArg)) {
      lastArg = (0, _assign2.default)({}, defaultRouteOptions, lastArg);
    } else {
      args.push(lastArg);
      lastArg = defaultRouteOptions;
    }
    var t = 2 - args.length;
    while (t-- > 0) {
      args.push(undefined);
    }
    args.push(lastArg);
    return args;
  }

  /**
   *
   *
   * @class Server
   * @public
   */

  var Server = function () {

    /**
     * Build the new server object.
     *
     * @constructor
     * @public
     */
    function Server() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      _classCallCheck(this, Server);

      this.config(options);
    }

    _createClass(Server, [{
      key: 'config',
      value: function config() {
        var _config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        var didOverrideConfig = _config.environment && this.environment && this.environment !== _config.environment;
        (0, _assert.default)(!didOverrideConfig, 'You cannot modify Mirage\'s environment once the server is created');
        this.environment = _config.environment || 'development';

        this._config = _config;

        this.timing = this.timing || _config.timing || 400;
        this.namespace = this.namespace || _config.namespace || '';
        this.urlPrefix = this.urlPrefix || _config.urlPrefix || '';
        this.trackRequests = _config.trackRequests;

        this._defineRouteHandlerHelpers();

        // Merge models from autogenerated Ember Data models with user defined models
        if (_emberData2.hasEmberData && _config.discoverEmberDataModels) {
          var models = {};
          (0, _assign2.default)(models, (0, _emberData.getModels)(), _config.models || {});
          _config.models = models;
        }

        if (this.db) {
          this.db.registerIdentityManagers(_config.identityManagers);
        } else {
          this.db = new _db.default(undefined, _config.identityManagers);
        }

        if (this.schema) {
          this.schema.registerModels(_config.models);
          this.serializerOrRegistry.registerSerializers(_config.serializers || {});
        } else {
          this.schema = new _schema.default(this.db, _config.models);
          this.serializerOrRegistry = new _serializerRegistry.default(this.schema, _config.serializers);
        }

        var hasFactories = this._hasModulesOfType(_config, 'factories');
        var hasDefaultScenario = _config.scenarios && _config.scenarios.hasOwnProperty('default');

        var didOverridePretenderConfig = _config.trackRequests !== undefined && this.pretender;
        (0, _assert.default)(!didOverridePretenderConfig, 'You cannot modify Pretender\'s request tracking once the server is created');
        this.pretender = this.pretender || createPretender(this);

        if (_config.baseConfig) {
          this.loadConfig(_config.baseConfig);
        }

        if (this.isTest()) {
          if (_config.testConfig) {
            this.loadConfig(_config.testConfig);
          }

          window.server = this; // TODO: Better way to inject server into test env
        }

        if (this.isTest() && hasFactories) {
          this.loadFactories(_config.factories);
        } else if (!this.isTest() && hasDefaultScenario) {
          this.loadFactories(_config.factories);
          _config.scenarios.default(this);
        } else {
          this.loadFixtures();
        }

        if (_config.useDefaultPassthroughs) {
          this._configureDefaultPassthroughs();
        }
      }
    }, {
      key: 'isTest',
      value: function isTest() {
        return this.environment === 'test';
      }
    }, {
      key: 'shouldLog',
      value: function shouldLog() {
        return typeof this.logging !== 'undefined' ? this.logging : !this.isTest();
      }
    }, {
      key: 'shouldTrackRequests',
      value: function shouldTrackRequests() {
        return Boolean(this.trackRequests);
      }
    }, {
      key: 'loadConfig',
      value: function loadConfig(config) {
        config.call(this);
        this.timing = this.isTest() ? 0 : this.timing || 0;
      }
    }, {
      key: 'passthrough',
      value: function passthrough() {
        var _this = this;

        for (var _len = arguments.length, paths = Array(_len), _key = 0; _key < _len; _key++) {
          paths[_key] = arguments[_key];
        }

        var verbs = ['get', 'post', 'put', 'delete', 'patch', 'options', 'head'];
        var lastArg = paths[paths.length - 1];

        if (paths.length === 0) {
          // paths = ['http://localhost:7357'];
          paths = ['/**', '/'];
        } else if (Array.isArray(lastArg)) {
          verbs = paths.pop();
        }

        verbs.forEach(function (verb) {
          paths.forEach(function (path) {
            var fullPath = _this._getFullPath(path);
            _this.pretender[verb](fullPath, _this.pretender.passthrough);
          });
        });
      }
    }, {
      key: 'loadFixtures',
      value: function loadFixtures() {
        var fixtures = this._config.fixtures;

        for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          args[_key2] = arguments[_key2];
        }

        if (args.length) {
          var camelizedArgs = args.map(_inflector.camelize);
          fixtures = _pick2.default.apply(undefined, [fixtures].concat(_toConsumableArray(camelizedArgs)));
        }

        this.db.loadData(fixtures);
      }
    }, {
      key: 'loadFactories',
      value: function loadFactories() {
        var _this2 = this;

        var factoryMap = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

        // Store a reference to the factories
        var currentFactoryMap = this._factoryMap || {};
        this._factoryMap = (0, _assign2.default)(currentFactoryMap, factoryMap);

        // Create a collection for each factory
        Object.keys(factoryMap).forEach(function (type) {
          var collectionName = (0, _normalizeName.toCollectionName)(type);
          _this2.db.createCollection(collectionName);
        });
      }
    }, {
      key: 'factoryFor',
      value: function factoryFor(type) {
        var camelizedType = (0, _inflector.camelize)(type);

        if (this._factoryMap && this._factoryMap[camelizedType]) {
          return this._factoryMap[camelizedType];
        }
      }
    }, {
      key: 'build',
      value: function build(type) {
        for (var _len3 = arguments.length, traitsAndOverrides = Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
          traitsAndOverrides[_key3 - 1] = arguments[_key3];
        }

        var traits = traitsAndOverrides.filter(function (arg) {
          return arg && typeof arg === 'string';
        });
        var overrides = (0, _find2.default)(traitsAndOverrides, function (arg) {
          return (0, _isPlainObject2.default)(arg);
        });
        var camelizedType = (0, _inflector.camelize)(type);

        // Store sequence for factory type as instance variable
        this.factorySequences = this.factorySequences || {};
        this.factorySequences[camelizedType] = this.factorySequences[camelizedType] + 1 || 0;

        var OriginalFactory = this.factoryFor(type);
        if (OriginalFactory) {
          OriginalFactory = OriginalFactory.extend({});
          var attrs = OriginalFactory.attrs || {};
          this._validateTraits(traits, OriginalFactory, type);
          var mergedExtensions = this._mergeExtensions(attrs, traits, overrides);
          this._mapAssociationsFromAttributes(type, attrs, overrides);
          this._mapAssociationsFromAttributes(type, mergedExtensions);

          var Factory = OriginalFactory.extend(mergedExtensions);
          var factory = new Factory();

          var sequence = this.factorySequences[camelizedType];
          return factory.build(sequence);
        } else {
          return overrides;
        }
      }
    }, {
      key: 'buildList',
      value: function buildList(type, amount) {
        (0, _assert.default)((0, _isInteger2.default)(amount), 'second argument has to be an integer, you passed: ' + (typeof amount === 'undefined' ? 'undefined' : _typeof(amount)));

        var list = [];

        for (var _len4 = arguments.length, traitsAndOverrides = Array(_len4 > 2 ? _len4 - 2 : 0), _key4 = 2; _key4 < _len4; _key4++) {
          traitsAndOverrides[_key4 - 2] = arguments[_key4];
        }

        for (var i = 0; i < amount; i++) {
          list.push(this.build.apply(this, [type].concat(traitsAndOverrides)));
        }

        return list;
      }
    }, {
      key: 'create',
      value: function create(type) {
        var _this3 = this;

        if (this._typeIsPluralForModel(type)) {
          console.warn('Mirage [deprecation]: You called server.create(\'' + type + '\'), but server.create was intended to be used with the singularized version of the model. Please change this to server.create(\'' + (0, _inflector.singularize)(type) + '\'). This behavior will be removed in 1.0.');

          type = (0, _inflector.singularize)(type);
        }

        // When there is a Model defined, we should return an instance
        // of it instead of returning the bare attributes.

        for (var _len5 = arguments.length, options = Array(_len5 > 1 ? _len5 - 1 : 0), _key5 = 1; _key5 < _len5; _key5++) {
          options[_key5 - 1] = arguments[_key5];
        }

        var traits = options.filter(function (arg) {
          return arg && typeof arg === 'string';
        });
        var overrides = (0, _find2.default)(options, function (arg) {
          return (0, _isPlainObject2.default)(arg);
        });
        var collectionFromCreateList = (0, _find2.default)(options, function (arg) {
          return arg && Array.isArray(arg);
        });

        var attrs = this.build.apply(this, [type].concat(_toConsumableArray(traits), [overrides]));
        var modelOrRecord = void 0;

        if (this.schema && this.schema[(0, _normalizeName.toCollectionName)(type)]) {
          var modelClass = this.schema[(0, _normalizeName.toCollectionName)(type)];

          modelOrRecord = modelClass.create(attrs);
        } else {
          var collection = void 0,
              collectionName = void 0;

          if (collectionFromCreateList) {
            collection = collectionFromCreateList;
          } else {
            collectionName = this.schema ? (0, _normalizeName.toInternalCollectionName)(type) : '_' + (0, _inflector.pluralize)(type);
            collection = this.db[collectionName];
          }

          (0, _assert.default)(collection, 'You called server.create(\'' + type + '\') but no model or factory was found.');
          modelOrRecord = collection.insert(attrs);
        }

        var OriginalFactory = this.factoryFor(type);
        if (OriginalFactory) {
          OriginalFactory.extractAfterCreateCallbacks({ traits: traits }).forEach(function (afterCreate) {
            afterCreate(modelOrRecord, _this3);
          });
        }

        return modelOrRecord;
      }
    }, {
      key: 'createList',
      value: function createList(type, amount) {
        (0, _assert.default)(this._modelOrFactoryExistsForTypeOrCollectionName(type), 'You called server.createList(\'' + type + '\') but no model or factory was found.');

        if (this._typeIsPluralForModel(type)) {
          console.warn('Mirage [deprecation]: You called server.createList(\'' + type + '\'), but server.createList was intended to be used with the singularized version of the model. Please change this to server.createList(\'' + (0, _inflector.singularize)(type) + '\'). This behavior will be removed in 1.0.');

          type = (0, _inflector.singularize)(type);
        }
        (0, _assert.default)((0, _isInteger2.default)(amount), 'second argument has to be an integer, you passed: ' + (typeof amount === 'undefined' ? 'undefined' : _typeof(amount)));

        var list = [];
        var collectionName = this.schema ? (0, _normalizeName.toInternalCollectionName)(type) : '_' + (0, _inflector.pluralize)(type);
        var collection = this.db[collectionName];

        for (var _len6 = arguments.length, traitsAndOverrides = Array(_len6 > 2 ? _len6 - 2 : 0), _key6 = 2; _key6 < _len6; _key6++) {
          traitsAndOverrides[_key6 - 2] = arguments[_key6];
        }

        for (var i = 0; i < amount; i++) {
          list.push(this.create.apply(this, [type].concat(traitsAndOverrides, [collection])));
        }

        return list;
      }
    }, {
      key: 'shutdown',
      value: function shutdown() {
        this.pretender.shutdown();
        if (this.environment === 'test') {
          window.server = undefined;
        }
      }
    }, {
      key: 'resource',
      value: function resource(resourceName) {
        var _this4 = this;

        var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
            only = _ref.only,
            except = _ref.except,
            path = _ref.path;

        resourceName = (0, _inflector.pluralize)(resourceName);
        path = path || '/' + resourceName;
        only = only || [];
        except = except || [];

        if (only.length > 0 && except.length > 0) {
          throw 'cannot use both :only and :except options';
        }

        var actionsMethodsAndsPathsMappings = {
          index: { methods: ['get'], path: '' + path },
          show: { methods: ['get'], path: path + '/:id' },
          create: { methods: ['post'], path: '' + path },
          update: { methods: ['put', 'patch'], path: path + '/:id' },
          delete: { methods: ['del'], path: path + '/:id' }
        };

        var allActions = Object.keys(actionsMethodsAndsPathsMappings);
        var actions = only.length > 0 && only || except.length > 0 && allActions.filter(function (action) {
          return except.indexOf(action) === -1;
        }) || allActions;

        actions.forEach(function (action) {
          var methodsWithPath = actionsMethodsAndsPathsMappings[action];

          methodsWithPath.methods.forEach(function (method) {
            return path === resourceName ? _this4[method](methodsWithPath.path) : _this4[method](methodsWithPath.path, resourceName);
          });
        });
      }
    }, {
      key: '_defineRouteHandlerHelpers',
      value: function _defineRouteHandlerHelpers() {
        var _this5 = this;

        [['get'], ['post'], ['put'], ['delete', 'del'], ['patch'], ['head'], ['options']].forEach(function (_ref2) {
          var _ref3 = _slicedToArray(_ref2, 2),
              verb = _ref3[0],
              alias = _ref3[1];

          _this5[verb] = function (path) {
            for (var _len7 = arguments.length, args = Array(_len7 > 1 ? _len7 - 1 : 0), _key7 = 1; _key7 < _len7; _key7++) {
              args[_key7 - 1] = arguments[_key7];
            }

            var _extractRouteArgument = extractRouteArguments(args),
                _extractRouteArgument2 = _slicedToArray(_extractRouteArgument, 3),
                rawHandler = _extractRouteArgument2[0],
                customizedCode = _extractRouteArgument2[1],
                options = _extractRouteArgument2[2];

            return _this5._registerRouteHandler(verb, path, rawHandler, customizedCode, options);
          };

          if (alias) {
            _this5[alias] = _this5[verb];
          }
        });
      }
    }, {
      key: '_serialize',
      value: function _serialize(body) {
        if (typeof body === 'string') {
          return body;
        } else {
          return JSON.stringify(body);
        }
      }
    }, {
      key: '_registerRouteHandler',
      value: function _registerRouteHandler(verb, path, rawHandler, customizedCode, options) {
        var _this6 = this;

        var routeHandler = new _routeHandler.default({
          schema: this.schema,
          verb: verb, rawHandler: rawHandler, customizedCode: customizedCode, options: options, path: path,
          serializerOrRegistry: this.serializerOrRegistry
        });

        var fullPath = this._getFullPath(path);
        var timing = options.timing !== undefined ? options.timing : function () {
          return _this6.timing;
        };

        return this.pretender[verb](fullPath, function (request) {
          return new Ember.RSVP.Promise(function (resolve) {
            Ember.RSVP.Promise.resolve(routeHandler.handle(request)).then(function (mirageResponse) {
              var _mirageResponse = _slicedToArray(mirageResponse, 3),
                  code = _mirageResponse[0],
                  headers = _mirageResponse[1],
                  response = _mirageResponse[2];

              resolve([code, headers, _this6._serialize(response)]);
            });
          });
        }, timing);
      }
    }, {
      key: '_hasModulesOfType',
      value: function _hasModulesOfType(modules, type) {
        var modulesOfType = modules[type];
        return modulesOfType ? Object.keys(modulesOfType).length > 0 : false;
      }
    }, {
      key: '_getFullPath',
      value: function _getFullPath(path) {
        path = path[0] === '/' ? path.slice(1) : path;
        var fullPath = '';
        var urlPrefix = this.urlPrefix ? this.urlPrefix.trim() : '';
        var namespace = '';

        // if there is a urlPrefix and a namespace
        if (this.urlPrefix && this.namespace) {
          if (this.namespace[0] === '/' && this.namespace[this.namespace.length - 1] === '/') {
            namespace = this.namespace.substring(0, this.namespace.length - 1).substring(1);
          }

          if (this.namespace[0] === '/' && this.namespace[this.namespace.length - 1] !== '/') {
            namespace = this.namespace.substring(1);
          }

          if (this.namespace[0] !== '/' && this.namespace[this.namespace.length - 1] === '/') {
            namespace = this.namespace.substring(0, this.namespace.length - 1);
          }

          if (this.namespace[0] !== '/' && this.namespace[this.namespace.length - 1] !== '/') {
            namespace = this.namespace;
          }
        }

        // if there is a namespace and no urlPrefix
        if (this.namespace && !this.urlPrefix) {
          if (this.namespace[0] === '/' && this.namespace[this.namespace.length - 1] === '/') {
            namespace = this.namespace.substring(0, this.namespace.length - 1);
          }

          if (this.namespace[0] === '/' && this.namespace[this.namespace.length - 1] !== '/') {
            namespace = this.namespace;
          }

          if (this.namespace[0] !== '/' && this.namespace[this.namespace.length - 1] === '/') {
            var namespaceSub = this.namespace.substring(0, this.namespace.length - 1);
            namespace = '/' + namespaceSub;
          }

          if (this.namespace[0] !== '/' && this.namespace[this.namespace.length - 1] !== '/') {
            namespace = '/' + this.namespace;
          }
        }

        // if no namespace
        if (!this.namespace) {
          namespace = '';
        }

        // check to see if path is a FQDN. if so, ignore any urlPrefix/namespace that was set
        if (/^https?:\/\//.test(path)) {
          fullPath += path;
        } else {
          // otherwise, if there is a urlPrefix, use that as the beginning of the path
          if (urlPrefix.length) {
            fullPath += urlPrefix[urlPrefix.length - 1] === '/' ? urlPrefix : urlPrefix + '/';
          }

          // add the namespace to the path
          fullPath += namespace;

          // add a trailing slash to the path if it doesn't already contain one
          if (fullPath[fullPath.length - 1] !== '/') {
            fullPath += '/';
          }

          // finally add the configured path
          fullPath += path;

          // if we're making a same-origin request, ensure a / is prepended and
          // dedup any double slashes
          if (!/^https?:\/\//.test(fullPath)) {
            fullPath = '/' + fullPath;
            fullPath = fullPath.replace(/\/+/g, '/');
          }
        }

        return fullPath;
      }
    }, {
      key: '_configureDefaultPassthroughs',
      value: function _configureDefaultPassthroughs() {
        var _this7 = this;

        defaultPassthroughs.forEach(function (passthroughUrl) {
          _this7.passthrough(passthroughUrl);
        });
      }
    }, {
      key: '_typeIsPluralForModel',
      value: function _typeIsPluralForModel(typeOrCollectionName) {
        var modelOrFactoryExists = this._modelOrFactoryExistsForTypeOrCollectionName(typeOrCollectionName);
        var isPlural = typeOrCollectionName === (0, _inflector.pluralize)(typeOrCollectionName);
        var isUncountable = (0, _inflector.singularize)(typeOrCollectionName) === (0, _inflector.pluralize)(typeOrCollectionName);

        return isPlural && !isUncountable && modelOrFactoryExists;
      }
    }, {
      key: '_modelOrFactoryExistsForTypeOrCollectionName',
      value: function _modelOrFactoryExistsForTypeOrCollectionName(typeOrCollectionName) {
        // Need this, since singular or plural can be passed in. Can assume singular (type) in 1.0.
        var type = (0, _inflector.singularize)(typeOrCollectionName);

        var modelExists = this.schema && this.schema.modelFor((0, _inflector.camelize)(type));
        var dbCollectionExists = this.db[(0, _normalizeName.toInternalCollectionName)(type)];

        return modelExists || dbCollectionExists;
      }
    }, {
      key: '_validateTraits',
      value: function _validateTraits(traits, factory, type) {
        traits.forEach(function (traitName) {
          if (!factory.isTrait(traitName)) {
            throw new Error('\'' + traitName + '\' trait is not registered in \'' + type + '\' factory');
          }
        });
      }
    }, {
      key: '_mergeExtensions',
      value: function _mergeExtensions(attrs, traits, overrides) {
        var allExtensions = traits.map(function (traitName) {
          return attrs[traitName].extension;
        });
        allExtensions.push(overrides || {});
        return allExtensions.reduce(function (accum, extension) {
          return (0, _assign2.default)(accum, extension);
        }, {});
      }
    }, {
      key: '_mapAssociationsFromAttributes',
      value: function _mapAssociationsFromAttributes(modelName, attributes) {
        var _this8 = this;

        var overrides = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

        Object.keys(attributes || {}).filter(function (attr) {
          return (0, _isAssociation.default)(attributes[attr]);
        }).forEach(function (attr) {
          var modelClass = _this8.schema.modelClassFor(modelName);
          var association = modelClass.associationFor(attr);

          (0, _assert.default)(association && association instanceof _belongsTo.default, 'You\'re using the `association` factory helper on the \'' + attr + '\' attribute of your ' + modelName + ' factory, but that attribute is not a `belongsTo` association. Read the Factories docs for more information: http://www.ember-cli-mirage.com/docs/v0.3.x/factories/#factories-and-relationships');

          var isSelfReferentialBelongsTo = association && association instanceof _belongsTo.default && association.modelName === modelName;

          (0, _assert.default)(!isSelfReferentialBelongsTo, 'You\'re using the association() helper on your ' + modelName + ' factory for ' + attr + ', which is a belongsTo self-referential relationship. You can\'t do this as it will lead to infinite recursion. You can move the helper inside of a trait and use it selectively.');

          var factoryAssociation = attributes[attr];
          var foreignKey = (0, _inflector.camelize)(attr) + 'Id';
          if (!overrides[attr]) {
            attributes[foreignKey] = _this8.create.apply(_this8, [association.modelName].concat(_toConsumableArray(factoryAssociation.traitsAndOverrides))).id;
          }
          delete attributes[attr];
        });
      }
    }]);

    return Server;
  }();

  exports.default = Server;
});