define('cc-components/pods/components/cc-composable-form/form-actions/component', ['exports', 'ember-composable-form/pods/components/composable-form/form-actions/component', 'cc-components/pods/components/cc-composable-form/form-actions/template'], function (exports, _component, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    layout: _template.default,
    primaryComponent: 'cc-composable-form/form-actions/form-action-primary',
    secondaryComponent: 'cc-composable-form/form-actions/form-action-secondary'
  });
});