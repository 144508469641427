define('ember-cli-mirage/route-handlers/base', ['exports', 'ember-cli-mirage/assert', 'ember-cli-mirage/utils/inflector', 'ember-cli-mirage/orm/associations/has-many'], function (exports, _assert, _inflector, _hasMany) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var PATH_VAR_REGEXP = /^:/;

  var BaseRouteHandler = function () {
    function BaseRouteHandler() {
      _classCallCheck(this, BaseRouteHandler);
    }

    _createClass(BaseRouteHandler, [{
      key: 'getModelClassFromPath',
      value: function getModelClassFromPath(fullPath) {
        if (!fullPath) {
          return;
        }
        var path = fullPath.split('/');
        var lastPath = void 0;
        while (path.length > 0) {
          lastPath = path.splice(-1)[0];
          if (lastPath && !PATH_VAR_REGEXP.test(lastPath)) {
            break;
          }
        }
        var modelName = (0, _inflector.dasherize)((0, _inflector.camelize)((0, _inflector.singularize)(lastPath)));

        return modelName;
      }
    }, {
      key: '_getIdForRequest',
      value: function _getIdForRequest(request, jsonApiDoc) {
        var id = void 0;
        if (request && request.params && request.params.id) {
          id = request.params.id;
        } else if (jsonApiDoc && jsonApiDoc.data && jsonApiDoc.data.id) {
          id = jsonApiDoc.data.id;
        }
        return id;
      }
    }, {
      key: '_getJsonApiDocForRequest',
      value: function _getJsonApiDocForRequest(request, modelName) {
        var body = void 0;
        if (request && request.requestBody) {
          body = JSON.parse(request.requestBody);
        }
        return this.serializerOrRegistry.normalize(body, modelName);
      }
    }, {
      key: '_getAttrsForRequest',
      value: function _getAttrsForRequest(request, modelName) {
        var _this = this;

        var json = this._getJsonApiDocForRequest(request, modelName);
        var id = this._getIdForRequest(request, json);
        var attrs = {};

        (0, _assert.default)(json.data && (json.data.attributes || json.data.type || json.data.relationships), 'You\'re using a shorthand or #normalizedRequestAttrs, but your serializer\'s normalize function did not return a valid JSON:API document. http://www.ember-cli-mirage.com/docs/v0.3.x/serializers/#normalizejson');

        if (json.data.attributes) {
          attrs = Object.keys(json.data.attributes).reduce(function (sum, key) {
            sum[(0, _inflector.camelize)(key)] = json.data.attributes[key];
            return sum;
          }, {});
        }

        if (json.data.relationships) {
          Object.keys(json.data.relationships).forEach(function (relationshipName) {
            var relationship = json.data.relationships[relationshipName];
            var modelClass = _this.schema.modelClassFor(modelName);
            var association = modelClass.associationFor((0, _inflector.camelize)(relationshipName));
            var valueForRelationship = void 0;

            (0, _assert.default)(association, 'You\'re passing the relationship \'' + relationshipName + '\' to the \'' + modelName + '\' model via a ' + request.method + ' to \'' + request.url + '\', but you did not define the \'' + relationshipName + '\' association on the \'' + modelName + '\' model. http://www.ember-cli-mirage.com/docs/v0.4.x/models/#associations');

            if (association.isPolymorphic) {
              valueForRelationship = relationship.data;
            } else if (association instanceof _hasMany.default) {
              valueForRelationship = relationship.data && relationship.data.map(function (rel) {
                return rel.id;
              });
            } else {
              valueForRelationship = relationship.data && relationship.data.id;
            }

            attrs[association.identifier] = valueForRelationship;
          }, {});
        }

        if (id) {
          attrs.id = id;
        }

        return attrs;
      }
    }, {
      key: '_getAttrsForFormRequest',
      value: function _getAttrsForFormRequest(_ref) {
        var requestBody = _ref.requestBody;

        var attrs = void 0;
        var urlEncodedParts = [];

        (0, _assert.default)(requestBody && typeof requestBody === 'string', 'You\'re using the helper method #normalizedFormData, but the request body is empty or not a valid url encoded string.');

        urlEncodedParts = requestBody.split('&');

        attrs = urlEncodedParts.reduce(function (a, urlEncodedPart) {
          var _urlEncodedPart$split = urlEncodedPart.split('='),
              _urlEncodedPart$split2 = _slicedToArray(_urlEncodedPart$split, 2),
              key = _urlEncodedPart$split2[0],
              value = _urlEncodedPart$split2[1];

          a[key] = decodeURIComponent(value.replace(/\+/g, ' '));
          return a;
        }, {});

        return attrs;
      }
    }]);

    return BaseRouteHandler;
  }();

  exports.default = BaseRouteHandler;
});