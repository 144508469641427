define('ember-cli-mirage/orm/associations/association', ['exports', 'ember-cli-mirage/utils/inflector'], function (exports, _inflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var Association = function () {
    function Association(modelName, opts) {
      _classCallCheck(this, Association);

      if ((typeof modelName === 'undefined' ? 'undefined' : _typeof(modelName)) === 'object') {
        // Received opts only
        this.modelName = undefined;
        this.opts = modelName;
      } else {
        // The modelName of the association. (Might not be passed in - set later
        // by schema).
        this.modelName = modelName ? (0, _inflector.dasherize)(modelName) : '';
        this.opts = opts || {};
      }

      // The key pointing to the association
      this.key = '';

      // The modelName that owns this association
      this.ownerModelName = '';
    }

    /**
     * A setter for schema, since we don't have a reference at constuction time.
     *
     * @method setSchema
     * @public
    */


    _createClass(Association, [{
      key: 'setSchema',
      value: function setSchema(schema) {
        this.schema = schema;
      }
    }, {
      key: 'isReflexive',
      value: function isReflexive() {
        var isExplicitReflexive = !!(this.modelName === this.ownerModelName && this.opts.inverse);
        var isImplicitReflexive = !!(this.opts.inverse === undefined && this.ownerModelName === this.modelName);

        return isExplicitReflexive || isImplicitReflexive;
      }
    }, {
      key: 'isPolymorphic',
      get: function get() {
        return this.opts.polymorphic;
      }
    }, {
      key: 'identifier',
      get: function get() {
        throw new Error('Subclasses of Association must implement a getter for identifier');
      }
    }]);

    return Association;
  }();

  exports.default = Association;
});