define('cc-components/pods/components/grid/grid-item/component', ['exports', 'cc-components/pods/components/grid/grid-item/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['colSpan', 'rowSpan', '_height', 'hidden:dn', ':relative'],

    columns: 12,

    height: null,

    hidden: false,

    layout: _template.default,

    rows: null,

    colSpan: Ember.computed('columns', function () {
      return this.columns ? 'col-span-' + this.columns : null;
    }),

    _height: Ember.computed('height', function () {
      return this.height ? 'h' + this.height : null;
    }),

    rowSpan: Ember.computed('rows', function () {
      return this.rows ? 'row-span-' + this.rows : null;
    })
  });
});