define('@html-next/flexi-layouts/components/flexi-container', ['exports', '@html-next/flexi-layouts/mixins/container', '@html-next/flexi-layouts/templates/components/flexi-container'], function (exports, _container, _flexiContainer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_container.default, {
    layout: _flexiContainer.default,
    tagName: 'container'
  });
});