define('ember-composable-form/pods/components/composable-form/base-element/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Component.extend({
    errorComponent: 'composable-form/error',
    hintComponent: 'composable-form/hint',
    labelComponent: 'composable-form/label',

    dirty: false,
    disabled: false,
    readonly: false,
    required: false,
    placeholder: '',
    hint: '',

    isValid: Ember.computed.empty('error'),

    error: Ember.computed('model.error', function () {
      var error = Ember.get(this, 'model.error');
      return error ? error[this.name] : null;
    }),

    firstError: Ember.computed('error', function () {
      var error = this.error;
      return error ? Ember.get(error, 'validation')[0] : null;
    }),

    showError: Ember.computed('isValid', function () {
      return !this.isValid;
    }),

    willRender: function willRender() {
      var _this = this;

      this._super.apply(this, arguments);
      Ember.defineProperty(this, '_value', Ember.computed('value', 'model.' + this.name, function () {
        var value = Ember.get(_this, 'model.' + _this.name);
        if (value && (typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object') {
          value = value.content;
        }
        return _this.value || value ? value : null;
      }));
    },


    actions: {
      _processValidation: function _processValidation() {
        this.send('setDirty');
        if (this.validateBeforeSubmit) {
          this.send('validate');
        }
      },
      setDirty: function setDirty() {
        Ember.set(this, 'dirty', true);
      },
      validate: function validate() {
        var model = this.model;
        model.validate(this.name);
      },
      update: function update(value) {
        var model = this.model;
        if (this.onUpdate) {
          this.onUpdate(value, model);
        } else {
          Ember.set(model, this.name, value);
        }
      }
    }
  });
});