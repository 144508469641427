define("ember-steps/components/step-manager/step", ["exports", "ember-steps/components/-tagless", "ember-steps/templates/components/step-manager/step", "@ember-decorators/object"], function (exports, _tagless, _step, _object) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
        var c = arguments.length,
            r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
            d;
        if ((typeof Reflect === "undefined" ? "undefined" : _typeof(Reflect)) === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
            if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
        }return c > 3 && r && Object.defineProperty(target, key, r), r;
    };
    // @ts-ignore: Ignore import of compiled template

    function failOnNameChange() {
        (true && !(false) && Ember.assert('The `name` property should never change'));
    }

    var StepComponent = function (_TaglessComponent) {
        _inherits(StepComponent, _TaglessComponent);

        function StepComponent() {
            _classCallCheck(this, StepComponent);

            var _this = _possibleConstructorReturn(this, (StepComponent.__proto__ || Object.getPrototypeOf(StepComponent)).apply(this, arguments));

            _this.layout = _step.default;
            var nameAttribute = Ember.get(_this, 'name');
            var name = Ember.isEmpty(nameAttribute) ? Symbol('generated step name') : nameAttribute;
            (true && !(typeof name !== 'boolean') && Ember.assert('Step name cannot be a boolean', typeof name !== 'boolean'));
            (true && !((typeof name === "undefined" ? "undefined" : _typeof(name)) !== 'object') && Ember.assert('Step name cannot be an object', (typeof name === "undefined" ? "undefined" : _typeof(name)) !== 'object'));

            if (name !== nameAttribute) {
                Ember.set(_this, 'name', name);
            }
            _this.addObserver('name', _this, failOnNameChange);
            _this['register-step'](_this);
            return _this;
        }

        _createClass(StepComponent, [{
            key: "willDestroyElement",
            value: function willDestroyElement() {
                this.removeObserver('name', this, failOnNameChange);
                this['remove-step'](this);
            }
        }, {
            key: "isActive",
            get: function get() {
                return this.currentStep === this.name;
            }
        }, {
            key: "hasNext",
            get: function get() {
                var name = this.name;
                return Ember.isPresent(this.transitions.pickNext(name));
            }
        }, {
            key: "hasPrevious",
            get: function get() {
                var name = this.name;
                return Ember.isPresent(this.transitions.pickPrevious(name));
            }
        }]);

        return StepComponent;
    }(_tagless.default);

    exports.default = StepComponent;

    __decorate([(0, _object.computed)('currentStep', 'name')], StepComponent.prototype, "isActive", null);
    __decorate([(0, _object.computed)('transitions.length')], StepComponent.prototype, "hasNext", null);
    __decorate([(0, _object.computed)('transitions.length')], StepComponent.prototype, "hasPrevious", null);
});