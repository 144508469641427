define('ember-composable-form/pods/components/composable-form/label/component', ['exports', 'ember-composable-form/pods/components/composable-form/label/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    classNameBindings: [':composable-form-label', 'display', '_margin'],

    tagName: 'label',

    marginSize: 3,

    display: 'dib',

    _margin: Ember.computed('marginSize', function () {
      return 'mb' + this.marginSize;
    })
  });
});