define("@html-next/flexi-layouts/utils/capitalize", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = capitalize;
  function capitalize(str) {
    return str.substr(0, 1).toUpperCase() + str.substr(1);
  }
});