define('ember-route-task-helper/utils/router', ['exports', 'ember-concurrency/-task-property'], function (exports, _taskProperty) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getRouter = getRouter;
  exports.getCurrentHandlerInfos = getCurrentHandlerInfos;
  exports.getCurrentRoutes = getCurrentRoutes;
  exports.findTaskInCurrentRouteHierarchy = findTaskInCurrentRouteHierarchy;
  function getRouter(context) {
    return Ember.getOwner(context).lookup('router:main');
  }

  function getCurrentHandlerInfos(router) {
    var routerLib = router._routerMicrolib || router.router;

    return routerLib.currentHandlerInfos;
  }

  function getCurrentRoutes(router) {
    return Ember.A(getCurrentHandlerInfos(router)).mapBy('handler').reverse();
  }

  function findTaskInCurrentRouteHierarchy(router, taskName) {
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = getCurrentRoutes(router)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var route = _step.value;

        var task = Ember.get(route, taskName);
        if (task instanceof _taskProperty.Task) {
          return task;
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return null;
  }
});