define('ember-click-outside/component', ['exports', 'ember-click-outside/mixin', 'ember-click-outside/utils'], function (exports, _mixin, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_mixin.default, {
    clickOutside: function clickOutside(e) {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      var exceptSelector = Ember.get(this, 'except-selector');
      if (exceptSelector && (0, _utils.closest)(e.target, exceptSelector)) {
        return;
      }

      var action = Ember.get(this, 'action');
      if (typeof action === 'function') {
        action(e);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._cancelOutsideListenerSetup = Ember.run.next(this, this.addClickOutsideListener);
    },
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(this._cancelOutsideListenerSetup);
      this.removeClickOutsideListener();
    }
  });
});