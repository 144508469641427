define('ember-composable-form/pods/components/composable-form/progress-bar/item/component', ['exports', 'ember-composable-form/pods/components/composable-form/progress-bar/item/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    classNameBindings: ['selected:active', 'visited:visited', 'disabled:disabled'],

    classNames: 'composable-form-progress-item',

    tagName: 'li',
    visited: false,

    disabled: Ember.computed.not('visited'),

    click: function click() {
      if (!this.disabled) {
        this.send('onClick');
        if (!this.visited) {
          this.send('setVisited');
        }
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.visited && this.selected) {
        this.send('setVisited');
      }
    },


    actions: {
      onClick: function onClick() {
        this.transitionTo(this.step);
      },
      setVisited: function setVisited() {
        Ember.set(this, 'visited', true);
      }
    }
  });
});