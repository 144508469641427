define('ember-steps/helpers/validate-transition', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.validateTransition = validateTransition;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function validateTransition(_ref, _ref2) {
    var _ref3 = _slicedToArray(_ref, 1),
        transition = _ref3[0];

    var validator = _ref2.with;

    return function () {
      return new Ember.RSVP.Promise(function (resolve) {
        validator(resolve);
      }).then(function () {
        transition();
      });
    };
  }

  exports.default = Ember.Helper.helper(validateTransition);
});