define('@html-next/flexi-layouts/helpers/-inject-layout', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({

    layoutService: Ember.inject.service('device/layout'),

    compute: function compute() {
      return this.get('layoutService');
    }
  });
});