define('ember-composable-form/pods/components/composable-form/checkbox/component', ['exports', 'ember-composable-form/pods/components/composable-form/base-element/component', 'ember-composable-form/pods/components/composable-form/checkbox/template'], function (exports, _component, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    layout: _template.default,
    tagName: 'label',
    classNames: ['composable-form-checkbox'],

    checked: false,
    disabled: false,

    change: function change() {
      this.send('update', !this.selected);
    },
    focusOut: function focusOut() {
      this.send('validate');
    },
    didInsertElement: function didInsertElement() {
      Ember.set(this, 'selected', Ember.get(this.model, this.name));
    }
  });
});