define('ember-composable-form/pods/components/composable-form/datepicker/component', ['exports', 'ember-composable-form/pods/components/composable-form/base-element/component', 'ember-composable-form/pods/components/composable-form/datepicker/template', 'moment'], function (exports, _component, _template, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    layout: _template.default,
    classNameBindings: ['disabled:disabled'],

    classNames: 'composable-form-datepicker',

    placeholder: 'Select Date',
    format: 'MM/DD/YYYY',
    verticalPosition: 'bottom',
    allowEmpty: true,
    disabled: false,
    readonly: false,

    triggerClass: '',

    _triggerClass: Ember.computed('name', function () {
      return 'composable-form-datepicker-trigger ' + this.triggerClass + ' ' + this.name;
    }),

    _minDate: Ember.computed('minDate', 'model.changes.@each', 'format', function () {
      var changes = Ember.get(this, 'model.changes');
      var format = this.format;
      var minDate = this.minDate;
      var date = void 0;

      if (!minDate) {
        return null;
      } else if (minDate === 'now') {
        date = (0, _moment.default)();
      } else if (!Ember.isEmpty(changes.filterBy('key', minDate))) {
        minDate = changes.filterBy('key', minDate);
        date = (0, _moment.default)(Ember.get(minDate, 'firstObject.value'));
      } else {
        date = (0, _moment.default)(minDate);
      }
      return date.isValid() ? date.format(format) : null;
    }),

    _maxDate: Ember.computed('maxDate', 'model.changes.@each', 'format', function () {
      var changes = Ember.get(this, 'model.changes');
      var format = this.format;
      var maxDate = this.maxDate;
      var date = void 0;

      if (!maxDate) {
        return null;
      } else if (maxDate === 'now') {
        date = (0, _moment.default)();
      } else if (!Ember.isEmpty(changes.filterBy('key', maxDate))) {
        maxDate = changes.filterBy('key', maxDate);
        date = (0, _moment.default)(Ember.get(maxDate, 'firstObject.value'));
      } else {
        date = (0, _moment.default)(maxDate);
      }
      return date.isValid() ? date.format(format) : null;
    }),

    actions: {
      setDate: function setDate(date) {
        this.send('update', date.moment.format('YYYY-MM-DD'));
        this.notifyPropertyChange('_value');
      }
    }
  });
});