define('ember-route-task-helper/utils/route-task', ['exports', 'ember-route-task-helper/utils/router'], function (exports, _router) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.routeTaskFromRouter = routeTaskFromRouter;
  exports.default = routeTask;
  function routeTaskFromRouter(router, taskName) {
    (true && !(router instanceof Ember.Router) && Ember.assert('[ember-route-task-helper] No router provided to `routeTaskFromRouter`', router instanceof Ember.Router));


    var task = (0, _router.findTaskInCurrentRouteHierarchy)(router, taskName);
    (true && !(task) && Ember.assert('[ember-route-task-helper] Unable to find task ' + taskName, task));

    for (var _len = arguments.length, params = Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
      params[_key - 2] = arguments[_key];
    }

    if (params.length) {
      return task._curry.apply(task, params);
    }

    return task;
  }

  function routeTask(context, taskName) {
    var router = (0, _router.getRouter)(context);
    (true && !(router) && Ember.assert('[ember-route-task-helper] Unable to lookup router', router));

    for (var _len2 = arguments.length, params = Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {
      params[_key2 - 2] = arguments[_key2];
    }

    return routeTaskFromRouter.apply(undefined, [router, taskName].concat(params));
  }
});