define('ember-route-task-helper/index', ['exports', 'ember-route-task-helper/utils/route-task'], function (exports, _routeTask) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'routeTask', {
    enumerable: true,
    get: function () {
      return _routeTask.default;
    }
  });
  Object.defineProperty(exports, 'routeTaskFromRouter', {
    enumerable: true,
    get: function () {
      return _routeTask.routeTaskFromRouter;
    }
  });
});