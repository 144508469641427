define('ember-composable-form/pods/components/composable-form/error/component', ['exports', 'ember-composable-form/pods/components/composable-form/error/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    classNameBindings: ['error::form-error-hidden'],

    classNames: ['composable-form-error f7'],

    tagName: 'small'
  });
});