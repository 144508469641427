define('cc-components/pods/components/cc-composable-form/form-actions/form-action-primary/component', ['exports', 'cc-components/pods/components/cc-composable-form/form-actions/form-action/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    gridContainerClass: 'form-action-primary',
    btnName: 'action-primary-button',
    noMargin: true
  });
});