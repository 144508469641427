define('ember-composable-form/pods/components/composable-form/fieldset/component', ['exports', 'ember-composable-form/pods/components/composable-form/fieldset/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    attributeBindings: ['disabled', 'name'],

    classNames: ['composable-form-fieldset'],

    classNameBindings: ['border', 'background', 'padding'],

    border: 'br3 ba b--light-gray',

    background: 'bg-white',

    padding: 'pa4',

    tagName: 'fieldset',

    disabled: false
  });
});