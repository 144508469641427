define('cc-components/pods/components/button/component', ['exports', 'cc-components/pods/components/button/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    tagName: 'button',

    classNameBindings: [':cc-button', 'disabled:disabled', 'icon', 'selected'],

    attributeBindings: ['disabled', 'name'],

    disabled: false,

    showSpinner: false,

    label: Ember.computed('icon', function () {
      if (!this.icon) {
        return 'Submit';
      }
    }),

    click: function click() {
      var clickAction = Ember.get(this, 'onClick');
      if (clickAction) {
        clickAction();
      }
    }
  });
});