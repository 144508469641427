define('cc-components/pods/components/cc-modal/component', ['exports', 'ember-modal-dialog/components/modal-dialog'], function (exports, _modalDialog) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalDialog.default.extend({
    hasOverlay: true,

    animatable: true,

    stack: 'modal-dialog',

    containerClass: Ember.computed('fixedHeight', function () {
      return ['cc-modal', this.fixedHeight ? 'fixedHeight' : ''].join(' ');
    }),

    overlayClass: 'cc-modal-backdrop o-90',

    tetherTarget: '#main',

    attachment: 'middle center',

    fixedHeight: true
  });
});