define('ember-composable-form/pods/components/composable-form/typeahead/component', ['exports', 'ember-composable-form/pods/components/composable-form/base-element/component', 'ember-composable-form/pods/components/composable-form/typeahead/template'], function (exports, _component, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    layout: _template.default,
    tagName: '',

    actions: {
      onInput: function onInput(value) {
        if (value === '') {
          this.send('update', value);
        }
      }
    }
  });
});