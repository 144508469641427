define('ember-composable-form/pods/components/composable-form/textarea/field/component', ['exports', 'ember-composable-form/pods/components/composable-form/textarea/field/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'textarea',
    attributeBindings: ['disabled', 'focusOut', 'input', 'placeholder', 'readonly', 'required', 'type', 'value', 'name'],
    classNames: ['composable-form-textarea--field', 'fw3'],
    classNameBindings: ['errorClass'],

    errorClass: Ember.computed('isValid', function () {
      return !Ember.get(this, 'isValid') ? 'has-error' : 'valid';
    }),

    disabled: false,
    placeholder: '',
    readonly: false
  });
});