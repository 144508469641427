define('ember-composable-form/pods/components/composable-form/progress-bar/component', ['exports', 'ember-composable-form/pods/components/composable-form/progress-bar/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    classNames: 'composable-form-progress-bar',

    contentComponent: 'composable-form/button',

    labelComponent: 'composable-form/label',

    tagName: 'ul'
  });
});