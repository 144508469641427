define('ember-composable-form/pods/components/composable-form/fieldset/legend/component', ['exports', 'ember-composable-form/pods/components/composable-form/fieldset/legend/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,
    tagName: 'legend',
    classNames: ['composable-form-fieldset--legend']
  });
});