define('ember-cli-mirage/serializers/rest-serializer', ['exports', 'ember-cli-mirage/serializers/active-model-serializer', 'ember-cli-mirage/utils/inflector'], function (exports, _activeModelSerializer, _inflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelSerializer.default.extend({
    serializeIds: 'always',

    keyForModel: function keyForModel(type) {
      return (0, _inflector.camelize)(type);
    },
    keyForAttribute: function keyForAttribute(attr) {
      return (0, _inflector.camelize)(attr);
    },
    keyForRelationship: function keyForRelationship(type) {
      return (0, _inflector.camelize)((0, _inflector.pluralize)(type));
    },
    keyForEmbeddedRelationship: function keyForEmbeddedRelationship(attributeName) {
      return (0, _inflector.camelize)(attributeName);
    },
    keyForRelationshipIds: function keyForRelationshipIds(type) {
      return (0, _inflector.camelize)((0, _inflector.pluralize)(type));
    },
    keyForForeignKey: function keyForForeignKey(relationshipName) {
      return (0, _inflector.camelize)((0, _inflector.singularize)(relationshipName));
    },
    getCoalescedIds: function getCoalescedIds(request) {
      return request.queryParams && request.queryParams.ids;
    }
  });
});