define('ember-composable-form/pods/components/composable-form/textarea/component', ['exports', 'ember-composable-form/pods/components/composable-form/base-element/component', 'ember-composable-form/pods/components/composable-form/textarea/template'], function (exports, _component, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    layout: _template.default,
    classNames: ['composable-form-textarea'],
    fieldComponent: 'composable-form/textarea/field'
  });
});