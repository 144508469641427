define('cc-components/pods/components/cc-composable-form/component', ['exports', 'cc-components/pods/components/cc-composable-form/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    actionsComponent: 'cc-composable-form/form-actions',
    composableFormClass: 'composable-form',
    showSummary: false,
    showProgress: false,

    didRender: function didRender() {
      // Need to wait until after render to access changeset errors.
      // Prevents property modified twice error.
      Ember.set(this, 'errors', Ember.get(this, 'model.errors'));
    },


    actions: {
      submit: function submit() {
        this.submit();
      },
      cancel: function cancel() {
        this.cancel();
      }
    }
  });
});